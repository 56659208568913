<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
     <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
    <ServerError  />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card>
          <v-card-title class=" elevation-1">
            Edit Profile
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-container v-if="userData">
              <v-form ref="profileForm" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="userData.firstname"
                      :rules="nameRules"
                      v-model="userData.firstname"
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="userData.lastname"
                      :rules="nameRules"
                      v-model="userData.lastname"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="userData.username"
                      :rules="nameRules"
                      v-model="userData.username"
                      label="Username"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-if="userData.dob"
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-if="userData.dob"
                          :rules="Rules"
                          v-model="userData.dob"
                          label="Date of Birth"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="userData.dob"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      v-if="userData.gender"
                      v-model="userData.gender"
                      :items="items"
                      label="Gender"
                      :rules="Rules"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="userData.phone"
                      :rules="phoneRules"
                      v-model="userData.phone"
                      label="Phone"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="userData.email"
                      :rules="emailRules"
                      v-model="userData.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-if="userData.type"
                      :rules="nameRules"
                      v-model="userData.type"
                      label="Role"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="">
                  <v-col cols="6">
                    <v-img
                      v-if="userData.image && !imagePreview"
                      :src="baseURL + userData.image"
                      width="150px"
                      height="150px"
                      style="align-center"
                      contain
                    />
                    <img
                      v-if="imagePreview"
                      :src="imagePreview"
                      v-show="showPreview"
                      width="150px"
                      height="150px"
                      class="align-center"
                      contain
                    />

                    <!-- <v-img
                     v-if="userData.image"
                      :src="'http://65.0.204.223/u/'+userData.image"
                      
                      width="150px"
                      height="150px"
                      class="center"
                    /> -->
                  </v-col>
                 
                </v-row>
                <v-row class="">
                   <v-col cols="12" md="6">
                    <v-btn outlined  @click="$refs.showImage.click()"
                      >Update Profile Picture
                      <v-item-group>
                        <input
                          v-show="false"
                          label="Update Profile Picture"
                          ref="showImage"
                          id="file"
                          type="file"
                          style="display: none"
                          @change="showImage"
                        />
                      </v-item-group>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-btn
                    :disabled="!valid"
                    outlined
                    color="red"
                    @click="validate()"
                  >
                    Update
                  </v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  props: ["adminid"],
  data() {
    return {
      showPreview: false,
      ServerError:false,
      userData: {
        firstname: null,
        lastname: null,
        username: null,
        dob: null,
        gender: null,
        phone: null,
        email: null,
        type: null,
        id: null,
      },
      files: null,
      imagePreview: "",
      date: null,
      menu: false,
      msg3: "",
      photo: null,
      image: null,
      uploadMsg: "",
      newImage: null,
      appLoading: false,
      showsnackbar: false,
      file: "",
      msg: null,
      valid: true,
      items: ["Male", "Female"],
      Rules: [(v) => !!v || "Required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      emailRules: [
        (v) => !!v || "Email is Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is Required",
        (v) => (v && v.length <= 10) || "Number must be less than 11",
        (v) => (v && v.length >= 10) || "Number must be grater than 10",
        (v) => /^\d{10}$/.test(v) || "Phone must be number",
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },

  mounted: function () {
    this.getData();
    var user = store.state.completeUserData;
    this.firstname = user.firstname;
    this.lastname = user.lastname;
    this.username = user.username;
    this.phone = user.phone;
    this.email = user.email;
    this.type = user.type;
    // this.date = user.dob.slice(0,10);
    this.gender = user.gender;
    this.image = user.image;
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    validate() {
      if (this.$refs.profileForm.validate()) {
        this.editAdmin();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/user/info",
        method: "GET",
        params: {
          id: this.adminid,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          store.commit("userData", response.data.data);
          this.userData = response.data.data;
          if (this.userData.dob)
            this.userData.dob = this.userData.dob.slice(0, 10);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editAdmin() {
      this.appLoading = true;
      // user["firstname"] = this.firstname;
      // user["lastname"] = this.lastname;
      // user["username"] = this.username;
      // user["phone"] = this.phone;
      // user["email"] = this.email;
      // user["type"] = this.type;
      // user["dob"] = this.date;
      // user["gender"] = this.gender;
      this.userData["id"] = this.adminid;
      axios({
        method: "POST",
        url: "/user/Edit",

        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: this.userData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (this.file) {
              this.uploadImage();
            } else {
              this.getData();
              this.$router.push("/settingsAdmin/profile");
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    showImage(e) {
      this.file = e.target.files[0];
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      this.showPreview = true;
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("photos", this.file);

      axios({
        method: "POST",
        url: "/User/UploadImage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.$router.push("/settingsAdmin/profile");
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>